html, body {
    margin: 0px;
    padding: 0px;
    border: 0px;
}

.react-multi-carousel-list {
    overflow: visible !important;
}

@keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }

.noNumberSpinner input[type="number"]::-webkit-inner-spin-button,
.noNumberSpinner input[type="number"]::-webkit-outer-spin-button {
  display: none;
}

body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: sans-serif;
}

.str-video {
  background-color: #272a30;
  color: #ffffff;
  height: 100dvh;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
  max-width: 100%;
}